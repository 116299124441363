<template>
  <div>
    <p class="mb-6">Movimientos por documento</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field class="mt-0 pt-0" outlined dense label="ID Documento" v-model="filtros.id_documento"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select v-model="filtros.tipo_documento" outlined dense :items="items" item-text="descripcion" item-value="valor" label="Tipo Documento"></v-select>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text> 

        <v-card-text>
        <TablaSimpleReporte
        :height="'500'" 
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
        ></TablaSimpleReporte>
      </v-card-text>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import funciones from '@/funciones/funciones.js'

// demos

export default {
   
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
  },
  watch: {},
  setup(props) {
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const nombreReporte = ref(`Inventario actual`)
    const items = [
      { descripcion: 'VENTAS', valor: '1,7' },
      { descripcion: 'COMPRA', valor: '2' },
      { descripcion: 'AJUSTE', valor: '3' },
      { descripcion: 'TRASLADO', valor: '4' },
      { descripcion: 'FABRICACION', valor: '5,6' },
      { descripcion: 'TRANSFORMACION', valor: '8' },
      { descripcion: 'NOTA DE CREDITO', valor: '9' }
    ]


    const cabecera = ref([
      { text: 'Id', value: 'id', format: { small: true } },
      { text: 'Id Producto', value: 'id_producto', format: { small: true } },

      { text: 'Producto', value: 'nombre', format: { small: true }, visible: true },
      { text: 'Referencia', value: 'referencia', format: { small: true }, visible: true },
      { text: 'Area', value: 'puesto', format: { small: true }, visible: true },
      { text: 'Almacen', value: 'almacen', format: { small: true }, visible: true },
      { text: 'Producto Tipo', value: 'producto_tipo', format: { small: true } },
      { text: 'Fecha', value: 'fecha', format: {  small: true } },

       
      { text: 'Entrada', value: 'entrada', format: { small: true } },
      { text: 'Salida', value: 'salida', format: { small: true } },
      { text: 'Movimiento', value: 'movimiento', format: { small: true } },
    ])

    const datos = ref([])
    const filtros = ref({
      id_documento: '',
      tipo_documento: '',
    })

    const cargando = ref(false)

    const buscarDatos = () => {
      cargando.value = true
      ProductoService.rptMovimientosPorDocumento({ ...filtros.value })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Movimientos por documento, ${items.find(ite=>ite.valor == filtros.value.tipo_documento).descripcion} #${filtros.value.id_documento}` 

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros, 
      items,
      funciones,
    }
  },
}
</script>
